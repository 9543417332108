import React from 'react'
import { Splash } from '@components/Splash'
import dynamic from 'next/dynamic'
import useIsClient from '@services/useIsClient'

// Dynamically import Persist to prevent SSR
const PersistNoSSR = dynamic(() => import('@store/Persist'), { ssr: false })

const PersistOnClient = ({ children }) => {
  const isClient = useIsClient()
  return isClient ? <PersistNoSSR loading={<Splash />}>{children}</PersistNoSSR> : children
}

export default PersistOnClient
