export const STRIPE_ENV = process.env.STRIPE_PUBLIC_APIKEY.indexOf('pk_test_') > -1 ? 'test' : 'live'

import {
  Stripe,
  PaymentSheetEventsEnum,
  ApplePayEventsEnum,
  GooglePayEventsEnum,
} from '@capacitor-community/stripe'

export async function initialize(): Promise<void> {
  return Stripe.initialize({
    publishableKey: process.env.STRIPE_PUBLIC_APIKEY,
  })
    .then((res) => {
      console.log({ initializeThen: res })
    })
    .catch((err) => {
      console.log({ initializeErr: err })
    })
}

export const listenForPaymentSheetCompleted = () => {
  Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
    console.log('PaymentSheetEventsEnum.Completed')
  })
}

export const createPaymentSheet = async ({ paymentIntent, customer, ephemeralKey }) => {
  try {
    await Stripe.createPaymentSheet({
      paymentIntentClientSecret: paymentIntent,
      customerId: customer,
      customerEphemeralKeySecret: ephemeralKey,
    })
  } catch (error) {
    console.log({ createPaymentSheetError: error })
  }
}

export const presentPaymentSheet = async () => {
  // present PaymentSheet and get result.
  const result = await Stripe.presentPaymentSheet()
  console.log({ in_ipresentPaymentSheet: result })
  if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
    // Happy path
    console.log({ in__paymentResult_ipresentPaymentSheet: result })
  }
}

export const isApplePayAvailable = async () => {
  try {
    let a = await Stripe.isApplePayAvailable()
    console.log({ in_isApplePayAvailable: a })
    return true
  } catch (error) {
    console.log({ in_isApplePayAvailableError: error })
  }

  return false
}

export const isGooglePayAvailable = async () => {
  try {
    let a = await Stripe.isGooglePayAvailable()
    console.log({ in_isGooglePayAvailable: a })
    return true
  } catch (error) {
    console.log({ in_isGooglePayAvailableError: error })
  }

  return false
}

export const listenForApplePayCompleted = () => {
  Stripe.addListener(ApplePayEventsEnum.Completed, () => {
    console.log('ApplePayEventsEnum.Completed')
  })
}

export const listenForGooglePayCompleted = () => {
  Stripe.addListener(GooglePayEventsEnum.Completed, () => {
    console.log('GooglePayEventsEnum.Completed')
  })
}

export const createApplePay = async ({ paymentIntentClientSecret, amount }) => {
  try {
    let a = await Stripe.createApplePay({
      paymentIntentClientSecret,
      paymentSummaryItems: [{ label: 'Hiro', amount }],
      merchantIdentifier: 'merchant.myhiro.club',
      countryCode: 'GB',
      currency: 'GBP',
    })
    console.log({ increateApplePay: a })
  } catch (error) {
    console.log({ createApplePayError: error })
  }
}

export const createGooglePay = async ({ paymentIntentClientSecret, amount }) => {
  try {
    let a = await Stripe.createGooglePay({
      paymentIntentClientSecret,
      paymentSummaryItems: [{ label: 'Hiro', amount }],
      merchantIdentifier: 'merchant.myhiro.club',
      countryCode: 'GB',
      currency: 'GBP',
    })
    console.log({ increateApplePay: a })
  } catch (error) {
    console.log({ createApplePayError: error })
  }
}

export const presentApplePay = Stripe.presentApplePay
export const presentGooglePay = Stripe.presentGooglePay
export const PresentApplePayEnum = ApplePayEventsEnum
export const PresentGooglePayEnum = GooglePayEventsEnum
