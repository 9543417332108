import { useEffect, useState } from "react";


const useIsClient = () => {
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(typeof window !== 'undefined'); // ✅ Runs only in the browser 
    }, []);
    return isClient;
};

export default useIsClient
