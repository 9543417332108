import React from 'react'
import { config } from '@fortawesome/fontawesome-svg-core'
import type { AppProps } from 'next/app'
import 'react-phone-number-input/style.css'
import 'animate.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'react-redux'
import store from '@store'
import 'public/global.css'
import PersistOnClient from '@components/PersistOnClient'
import { AnimatePresence } from '@cnd/elements'

const queryClient = new QueryClient()

config.autoAddCss = false

const App = ({ Component, pageProps, router }: AppProps) => (
  <Provider store={store}>
    <PersistOnClient>
      <QueryClientProvider client={queryClient}>
        <AnimatePresence mode="wait" initial={false} onExitComplete={() => window.scrollTo(0, 0)}>
          <Component {...pageProps} key={router.asPath} />
        </AnimatePresence>
      </QueryClientProvider>
    </PersistOnClient>
  </Provider>
)

export default App
